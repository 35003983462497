
// @ts-nocheck
import locale__app_i18n_src_langs_en_GB_ts from "../i18n/src/langs/en-GB.ts?hash=78263884&locale=en-GB";
import locale__app_i18n_src_langs_pl_PL_ts from "../i18n/src/langs/pl-PL.ts?hash=e18bd7d8&locale=pl-PL";
import locale__app_i18n_src_langs_de_DE_ts from "../i18n/src/langs/de-DE.ts?hash=b84912d6&locale=de-DE";


export const localeCodes =  [
  "en-GB",
  "pl-PL",
  "de-DE"
]

export const localeLoaders = {
  "en-GB": [{ key: "../i18n/src/langs/en-GB.ts", load: () => Promise.resolve(locale__app_i18n_src_langs_en_GB_ts), cache: false }],
  "pl-PL": [{ key: "../i18n/src/langs/pl-PL.ts", load: () => Promise.resolve(locale__app_i18n_src_langs_pl_PL_ts), cache: false }],
  "de-DE": [{ key: "../i18n/src/langs/de-DE.ts", load: () => Promise.resolve(locale__app_i18n_src_langs_de_DE_ts), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n/config.ts?hash=4caee762&config=1" /* webpackChunkName: "__i18n_config_4caee762" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/config",
  "locales": [
    {
      "code": "en-GB",
      "language": "en-GB",
      "files": [
        "/app/i18n/src/langs/en-GB.ts"
      ]
    },
    {
      "code": "pl-PL",
      "language": "pl-PL",
      "files": [
        "/app/i18n/src/langs/pl-PL.ts"
      ]
    },
    {
      "code": "de-DE",
      "language": "de-DE",
      "files": [
        "/app/i18n/src/langs/de-DE.ts"
      ]
    }
  ],
  "defaultLocale": "de-DE",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./i18n/src/langs/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en-GB",
    "language": "en-GB",
    "files": [
      {
        "path": "/app/i18n/src/langs/en-GB.ts"
      }
    ]
  },
  {
    "code": "pl-PL",
    "language": "pl-PL",
    "files": [
      {
        "path": "/app/i18n/src/langs/pl-PL.ts"
      }
    ]
  },
  {
    "code": "de-DE",
    "language": "de-DE",
    "files": [
      {
        "path": "/app/i18n/src/langs/de-DE.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
