import { default as _91_46_46_46all_93FQm56YYVyGMeta } from "/app/pages/[...all].vue?macro=true";
import { default as _91productId_93_45investorseC32IDZw4zMeta } from "/app/pages/account/[productId]-investors.vue?macro=true";
import { default as addressOWzGt9wlBZMeta } from "/app/pages/account/address.vue?macro=true";
import { default as companies2QoIobtBWCMeta } from "/app/pages/account/companies.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as investmentsx58eB6BgxTMeta } from "/app/pages/account/investments.vue?macro=true";
import { default as orderMBG0Sh5etWMeta } from "/app/pages/account/order.vue?macro=true";
import { default as paymentI6FeC2iYYyMeta } from "/app/pages/account/payment.vue?macro=true";
import { default as profilelwi6rEuniiMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as recoverEISGL130H3Meta } from "/app/pages/account/recover.vue?macro=true";
import { default as cart2rqhQ2dGfnMeta } from "/app/pages/checkout/cart.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexsLK8t7rixMMeta } from "/app/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidnqzqsGqNCxMeta } from "/app/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaid4r7kp3IwBlMeta } from "/app/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as index7qPPKwf2MoMeta } from "/app/pages/investor-portal/index.vue?macro=true";
import { default as _91id_93G2TrayhPtiMeta } from "/app/pages/investor-portal/investments/[id].vue?macro=true";
import { default as indexzPVubQKTsFMeta } from "/app/pages/investor-portal/investments/index.vue?macro=true";
import { default as newseJIxvVXf4EMeta } from "/app/pages/investor-portal/news.vue?macro=true";
import { default as profileDWATwbeEg7Meta } from "/app/pages/investor-portal/profile.vue?macro=true";
import { default as investor_45portalXEa29wZlc1Meta } from "/app/pages/investor-portal.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as newsletter_45subscribeE0mtl5vIW6Meta } from "/app/pages/newsletter-subscribe.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as shopwareRm30fhU7w1Meta } from "/app/pages/shopware.vue?macro=true";
import { default as wishlisttHOV2I8jKWMeta } from "/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "all___en-GB",
    path: "/en-GB/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___pl-PL",
    path: "/pl-PL/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___de-DE",
    path: "/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "account-productId-investors___en-GB",
    path: "/en-GB/account/:productId()-investors",
    meta: _91productId_93_45investorseC32IDZw4zMeta || {},
    component: () => import("/app/pages/account/[productId]-investors.vue")
  },
  {
    name: "account-productId-investors___pl-PL",
    path: "/pl-PL/account/:productId()-investors",
    meta: _91productId_93_45investorseC32IDZw4zMeta || {},
    component: () => import("/app/pages/account/[productId]-investors.vue")
  },
  {
    name: "account-productId-investors___de-DE",
    path: "/account/:productId()-investors",
    meta: _91productId_93_45investorseC32IDZw4zMeta || {},
    component: () => import("/app/pages/account/[productId]-investors.vue")
  },
  {
    name: "account-address___en-GB",
    path: "/en-GB/account/address",
    meta: addressOWzGt9wlBZMeta || {},
    component: () => import("/app/pages/account/address.vue")
  },
  {
    name: "account-address___pl-PL",
    path: "/pl-PL/account/address",
    meta: addressOWzGt9wlBZMeta || {},
    component: () => import("/app/pages/account/address.vue")
  },
  {
    name: "account-address___de-DE",
    path: "/account/address",
    meta: addressOWzGt9wlBZMeta || {},
    component: () => import("/app/pages/account/address.vue")
  },
  {
    name: "account-companies___en-GB",
    path: "/en-GB/account/companies",
    meta: companies2QoIobtBWCMeta || {},
    component: () => import("/app/pages/account/companies.vue")
  },
  {
    name: "account-companies___pl-PL",
    path: "/pl-PL/account/companies",
    meta: companies2QoIobtBWCMeta || {},
    component: () => import("/app/pages/account/companies.vue")
  },
  {
    name: "account-companies___de-DE",
    path: "/account/companies",
    meta: companies2QoIobtBWCMeta || {},
    component: () => import("/app/pages/account/companies.vue")
  },
  {
    name: "account___en-GB",
    path: "/en-GB/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account___pl-PL",
    path: "/pl-PL/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account___de-DE",
    path: "/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-investments___en-GB",
    path: "/en-GB/account/investments",
    meta: investmentsx58eB6BgxTMeta || {},
    component: () => import("/app/pages/account/investments.vue")
  },
  {
    name: "account-investments___pl-PL",
    path: "/pl-PL/account/investments",
    meta: investmentsx58eB6BgxTMeta || {},
    component: () => import("/app/pages/account/investments.vue")
  },
  {
    name: "account-investments___de-DE",
    path: "/account/investments",
    meta: investmentsx58eB6BgxTMeta || {},
    component: () => import("/app/pages/account/investments.vue")
  },
  {
    name: "account-order___en-GB",
    path: "/en-GB/account/order",
    meta: orderMBG0Sh5etWMeta || {},
    component: () => import("/app/pages/account/order.vue")
  },
  {
    name: "account-order___pl-PL",
    path: "/pl-PL/account/order",
    meta: orderMBG0Sh5etWMeta || {},
    component: () => import("/app/pages/account/order.vue")
  },
  {
    name: "account-order___de-DE",
    path: "/account/order",
    meta: orderMBG0Sh5etWMeta || {},
    component: () => import("/app/pages/account/order.vue")
  },
  {
    name: "account-payment___en-GB",
    path: "/en-GB/account/payment",
    meta: paymentI6FeC2iYYyMeta || {},
    component: () => import("/app/pages/account/payment.vue")
  },
  {
    name: "account-payment___pl-PL",
    path: "/pl-PL/account/payment",
    meta: paymentI6FeC2iYYyMeta || {},
    component: () => import("/app/pages/account/payment.vue")
  },
  {
    name: "account-payment___de-DE",
    path: "/account/payment",
    meta: paymentI6FeC2iYYyMeta || {},
    component: () => import("/app/pages/account/payment.vue")
  },
  {
    name: "account-profile___en-GB",
    path: "/en-GB/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: "account-profile___pl-PL",
    path: "/pl-PL/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: "account-profile___de-DE",
    path: "/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: "account-recover___en-GB",
    path: "/en-GB/account/recover",
    component: () => import("/app/pages/account/recover.vue")
  },
  {
    name: "account-recover___pl-PL",
    path: "/pl-PL/account/recover",
    component: () => import("/app/pages/account/recover.vue")
  },
  {
    name: "account-recover___de-DE",
    path: "/account/recover",
    component: () => import("/app/pages/account/recover.vue")
  },
  {
    name: "checkout-cart___en-GB",
    path: "/en-GB/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    component: () => import("/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___pl-PL",
    path: "/pl-PL/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    component: () => import("/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___de-DE",
    path: "/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    component: () => import("/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout___en-GB",
    path: "/en-GB/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl-PL",
    path: "/pl-PL/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___de-DE",
    path: "/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-id___en-GB",
    path: "/en-GB/checkout/success/:id()",
    component: () => import("/app/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___pl-PL",
    path: "/pl-PL/checkout/success/:id()",
    component: () => import("/app/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___de-DE",
    path: "/checkout/success/:id()",
    component: () => import("/app/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id-paid___en-GB",
    path: "/en-GB/checkout/success/:id()/paid",
    component: () => import("/app/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___pl-PL",
    path: "/pl-PL/checkout/success/:id()/paid",
    component: () => import("/app/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___de-DE",
    path: "/checkout/success/:id()/paid",
    component: () => import("/app/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-unpaid___en-GB",
    path: "/en-GB/checkout/success/:id()/unpaid",
    component: () => import("/app/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "checkout-success-id-unpaid___pl-PL",
    path: "/pl-PL/checkout/success/:id()/unpaid",
    component: () => import("/app/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "checkout-success-id-unpaid___de-DE",
    path: "/checkout/success/:id()/unpaid",
    component: () => import("/app/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: investor_45portalXEa29wZlc1Meta?.name,
    path: "/en-GB/investor-portal",
    meta: investor_45portalXEa29wZlc1Meta || {},
    component: () => import("/app/pages/investor-portal.vue"),
    children: [
  {
    name: "investor-portal___en-GB",
    path: "",
    component: () => import("/app/pages/investor-portal/index.vue")
  },
  {
    name: "investor-portal-investments-id___en-GB",
    path: "investments/:id()",
    component: () => import("/app/pages/investor-portal/investments/[id].vue")
  },
  {
    name: "investor-portal-investments___en-GB",
    path: "investments",
    component: () => import("/app/pages/investor-portal/investments/index.vue")
  },
  {
    name: "investor-portal-news___en-GB",
    path: "news",
    component: () => import("/app/pages/investor-portal/news.vue")
  },
  {
    name: "investor-portal-profile___en-GB",
    path: "profile",
    component: () => import("/app/pages/investor-portal/profile.vue")
  }
]
  },
  {
    name: investor_45portalXEa29wZlc1Meta?.name,
    path: "/pl-PL/investor-portal",
    meta: investor_45portalXEa29wZlc1Meta || {},
    component: () => import("/app/pages/investor-portal.vue"),
    children: [
  {
    name: "investor-portal___pl-PL",
    path: "",
    component: () => import("/app/pages/investor-portal/index.vue")
  },
  {
    name: "investor-portal-investments-id___pl-PL",
    path: "investments/:id()",
    component: () => import("/app/pages/investor-portal/investments/[id].vue")
  },
  {
    name: "investor-portal-investments___pl-PL",
    path: "investments",
    component: () => import("/app/pages/investor-portal/investments/index.vue")
  },
  {
    name: "investor-portal-news___pl-PL",
    path: "news",
    component: () => import("/app/pages/investor-portal/news.vue")
  },
  {
    name: "investor-portal-profile___pl-PL",
    path: "profile",
    component: () => import("/app/pages/investor-portal/profile.vue")
  }
]
  },
  {
    name: investor_45portalXEa29wZlc1Meta?.name,
    path: "/investor-portal",
    meta: investor_45portalXEa29wZlc1Meta || {},
    component: () => import("/app/pages/investor-portal.vue"),
    children: [
  {
    name: "investor-portal___de-DE",
    path: "",
    component: () => import("/app/pages/investor-portal/index.vue")
  },
  {
    name: "investor-portal-investments-id___de-DE",
    path: "investments/:id()",
    component: () => import("/app/pages/investor-portal/investments/[id].vue")
  },
  {
    name: "investor-portal-investments___de-DE",
    path: "investments",
    component: () => import("/app/pages/investor-portal/investments/index.vue")
  },
  {
    name: "investor-portal-news___de-DE",
    path: "news",
    component: () => import("/app/pages/investor-portal/news.vue")
  },
  {
    name: "investor-portal-profile___de-DE",
    path: "profile",
    component: () => import("/app/pages/investor-portal/profile.vue")
  }
]
  },
  {
    name: "login___en-GB",
    path: "/en-GB/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "login___pl-PL",
    path: "/pl-PL/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "login___de-DE",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "newsletter-subscribe___en-GB",
    path: "/en-GB/newsletter-subscribe",
    component: () => import("/app/pages/newsletter-subscribe.vue")
  },
  {
    name: "newsletter-subscribe___pl-PL",
    path: "/pl-PL/newsletter-subscribe",
    component: () => import("/app/pages/newsletter-subscribe.vue")
  },
  {
    name: "newsletter-subscribe___de-DE",
    path: "/newsletter-subscribe",
    component: () => import("/app/pages/newsletter-subscribe.vue")
  },
  {
    name: "register___en-GB",
    path: "/en-GB/register",
    component: () => import("/app/pages/register.vue")
  },
  {
    name: "register___pl-PL",
    path: "/pl-PL/register",
    component: () => import("/app/pages/register.vue")
  },
  {
    name: "register___de-DE",
    path: "/register",
    component: () => import("/app/pages/register.vue")
  },
  {
    name: "reset-password___en-GB",
    path: "/en-GB/reset-password",
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "reset-password___pl-PL",
    path: "/pl-PL/reset-password",
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "reset-password___de-DE",
    path: "/reset-password",
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "search___en-GB",
    path: "/en-GB/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___pl-PL",
    path: "/pl-PL/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___de-DE",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "shopware___en-GB",
    path: "/en-GB/shopware",
    component: () => import("/app/pages/shopware.vue")
  },
  {
    name: "shopware___pl-PL",
    path: "/pl-PL/shopware",
    component: () => import("/app/pages/shopware.vue")
  },
  {
    name: "shopware___de-DE",
    path: "/shopware",
    component: () => import("/app/pages/shopware.vue")
  },
  {
    name: "wishlist___en-GB",
    path: "/en-GB/wishlist",
    component: () => import("/app/pages/wishlist.vue")
  },
  {
    name: "wishlist___pl-PL",
    path: "/pl-PL/wishlist",
    component: () => import("/app/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-DE",
    path: "/wishlist",
    component: () => import("/app/pages/wishlist.vue")
  }
]