class OcStyledContent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    const wrapper = document.createElement("div");
    const text = this.getAttribute("data-text");
    wrapper.innerHTML = text ? text : '';

    const style = document.createElement('style');
    style.innerHTML = `
     img {
       width: 100%;
       max-width: 100%;
       height: auto;
     }
    `

    shadow.appendChild(style);
    shadow.appendChild(wrapper);
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  customElements.define("oc-styled-content", OcStyledContent);
})
