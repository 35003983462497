import { useI18n } from '#imports'

export const useTranslations = () => {
    const { mergeLocaleMessage } = useI18n()
    const { apiClient } = useShopwareContext();

    const fetchAndMergeTranslations = async () => {
        try {
            // Fetch translations from your Shopware API
            const translations = await apiClient.invoke('frontendSnippets get /frontend-snippets', {
            })

            if (translations) {
                // Assuming the API returns an object with locale keys
                Object.entries(translations.data).forEach(([localeKey, messages]) => {
                    mergeLocaleMessage(localeKey, messages)
                })
            }
        } catch (error) {
            console.error('Failed to fetch translations:', error)
        }
    }

    return {
        fetchAndMergeTranslations
    }
}
